@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/Montserrat-Regular.ttf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("/assets/fonts/Montserrat-Bold.ttf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("/assets/fonts/Montserrat-ExtraBold.ttf") format("opentype");
  font-weight: normal;
}

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

/** Css **/

@mixin home-heading() {
  user-select: none;
  -webkit-user-select: none;

  font-size: 1.8rem;
  font-weight: 900;
  color: #707070;
  text-align: center;

  margin: 1.8rem;

  @media screen and (max-width: 600px) {
    text-align: left;
    margin: 1.8rem 0;
  }

  @media screen and (min-width: 1680px) {
    font-size: 2.4rem;
  }

  @media screen and (min-width: 2560px) {
    font-size: 3.2rem;
  }

  @media screen and (min-width: 3840px) {
    font-size: 4.4rem;
    margin: 3.8rem;
  }
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
  outline: 0;
}

html {
  font-size: 10px !important;
  color: var(--eng-contrast-500) !important;
  font-family: "Open Sans", sans-serif !important;

  @media (max-width: 1600px) {
    font-size: 15px !important;
  }

  @media (max-width: 1400px) {
    font-size: 14px !important;
  }

  @media (max-width: 1200px) {
    font-size: 13px !important;
  }

  @media (max-width: 768px) {
    font-size: 12px !important;
    overflow-y: scroll;
  }

  body {
    font-family: unset;
    font-size: unset;
    background-color: white;
    color: black;

    overflow: auto;

    min-height: unset !important;
    min-width: unset !important;
  }

  min-height: unset !important;
  min-width: unset !important;

}

a {
  text-decoration: none;
}

.pwd-input {
  padding-right: 3rem;
}

.menu-modal-container {
  width: 80%;
  height: 56%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: .8rem;
  box-shadow: 0px 0px 0px 50vh #00000080;
}

.home-filter-modal-container {
  width: 80%;
  transition: 2s;
  box-shadow: 0px 0px 0px 50vh #00000080;
}

.lead-modal {
  --border-radius: .5rem;
  --height: 85%;

  background-color: #000000e5;

  @media screen and (min-width: 1600px) {
    --max-height: 600px;
  }
}

.all-categories-modal {
  --width: 90%;
  --height: 90%;
  --border-radius: 1rem;

  @media screen and (min-width: 1680px) {
    --border-radius: 1.5rem;
  }

  @media screen and (min-width: 2560px) {
    --border-radius: 2rem;
  }

  @media screen and (min-width: 3860px) {
    --border-radius: 4rem;
  }
}

.side-menu-modal-container {
  width: 80%;
  box-shadow: 0px 0px 0px 50vh #00000080;

  position: absolute;
  left: 20%;
}

.change-password-modal-container {
  --border-radius: 0.6rem;

  --height: 80vh;
  --width: 40vw;

  @media screen and (max-width: 769px) {
    --width: 85vw;
  }
}

.lead-popover-container {
  --height: 35%;
  --width: 93%;
}

.rating-modal-container {
  --height: auto;
  --border-radius: 0.6rem;

  @media only screen and (max-width:600px) {
    --height: 35rem;
  }
}

ion-toast.my-toast {
  font-family: 'Montserrat';
  --max-width: 10000px;

  @media screen and (min-width: 1024px) {
    margin-left: 29.5%;

    --width: 31rem;
  }

  @media screen and (min-width: 1440px) {
    font-size: 1.2rem;
    margin-left: 31%;

    --height: 5rem;
    --width: 35rem;
  }

  @media screen and (min-width: 1680px) {
    font-size: 2.2rem;
    margin-left: 30.2%;

    --height: 9rem;
    --width: 65rem;
  }

  @media screen and (min-width: 2560px) {
    font-size: 3rem;
    margin-left: 34%;

    --height: 12rem;
    --width: 100rem;
  }

  @media screen and (min-width: 3860px) {
    font-size: 5rem;
    margin-left: 33%;

    --height: 20rem;
    --width: 170rem;
  }
}

ion-toast.my-toast::part(header) {
  font-family: 'Montserrat';

  @media screen and (min-width: 1680px) {
    margin-bottom: 1.8rem;
  }

  @media screen and (min-width: 2560px) {
    margin-bottom: 2.5rem;
  }

  @media screen and (min-width: 3860px) {
    margin-bottom: 4.5rem;
  }
}

ion-toast.my-toast::part(button) {
  font-family: 'Montserrat';

  @media screen and (min-width: 1440px) {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 1680px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 2560px) {
    font-size: 3rem;
  }

  @media screen and (min-width: 3860px) {
    font-size: 5rem;
  }
}

:root {
  --default-box-shadow: 0px 0px 6px #00000029;
  --main-gradient-color: linear-gradient(90deg, #ed7944, #ee445c);
  --main-orange: #ed7944;
  --main-red: #ee445c;

  --main-light-gray: #dddddd;

  ion-spinner,
  .large-spinner {
    height: 10rem;
    width: 10rem;
  }

  ion-spinner,
  .medium-spinner {
    height: 5rem;
    width: 6rem;
  }

  ion-spinner,
  .load-more-spinner {
    height: 1.7rem;
    width: 2rem;

    @media only screen and (max-width:600px) {
      height: 1.7rem;
      width: 2rem;
    }
  }

  ion-spinner,
  .small-spinner {
    height: 0.7rem;
    width: 1rem;

    @media only screen and (max-width:600px) {
      height: 1.7rem;
      width: 2rem;
    }
  }

  ion-spinner,
  .medium-spinner {
    height: 1.5rem;
    width: 1.5rem;

    @media only screen and (max-width:600px) {
      height: 1.7rem;
      width: 2rem;
    }
  }

  ion-spinner,
  .white-spinner {
    color: white;
  }

  ion-spinner,
  .orange-spinner {
    color: #ee445c;
  }
}

/** Safe Area **/

@supports (padding-top: 20px) {
  html {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}

@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: constant(safe-area-inset-top);
    --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    --ion-safe-area-left: constant(safe-area-inset-left);
    --ion-safe-area-right: constant(safe-area-inset-right);
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
}

@keyframes fadeIn {
  0% {
    filter: opacity(0);
  }

  100% {
    filter: opacity(1);
  }
}

@keyframes fadeOut {
  0% {
    filter: opacity(1);
  }

  100% {
    filter: opacity(0);
  }
}